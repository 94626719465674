/* eslint-disable no-console */
const $ = window.jQuery;

function sanitize( s ) {
	return s.replace( '&', '&amp;' ).replace( '<', '&lt;' ).replace( '>', '&gt;' );
}

export function updateInfoBox( scene ) {
	console.log( 'updateInfoBox' );
	const titleElement = $( '#info-box .top-bar .title' ).empty();
	const textElement = $( '#info-box p.desc' ).empty();

	const title = sanitize( scene.data.name );
	if ( title ) {
		titleElement.text( title );
	}

	const text = scene.data.text;
	if ( text ) {
		textElement.html( sanitize( text ) );
	}

	console.log( scene.data );
	const parking = scene.data.parking;
	const parkDist = scene.data.parkDist;
	const pathDist = scene.data.pathDist;
	const useType = scene.data.useType;

	const pricePerGrave = scene.data.pricePerGrave;
	const pricePerTree = scene.data.pricePerTree;
	const categorieName = scene.data.categorieName;

	const table = $( '#info-box div.pseudo-table' );
	const tableDiv = $( '<div class="pseudo-table-body"></div>' );

	if ( parking && parkDist ) {
		tableDiv.append(
			`<div class='tr parking'>
				<div class='th'>Entfernung vom ${ parking }:</div>
				<div class='td'>ca. ${ parkDist }&thinsp;m</div>
			</div>`
		);
	}
	if ( pathDist ) {
		tableDiv.append(
			`<div class='tr pathDist'>
				<div class='th'>Entfernung zum Fußweg:</div>
				<div class='td'>ca. ${ pathDist }&thinsp;m</div>
			</div>`
		);
	}

	if ( 'gem' === useType ) {
		//const pricePerGrave = scene.data.pricePerGrave;
		//const priceStr = pricePerGrave.toString().replace( /\B(?=(\d{3})+(?!\d))/g, '.' );
		if ( categorieName ) {
			tableDiv.append(
				`<div class='tr cat'>
					<div class='th'>Gemeinschafsbaum der:</div>
					<div class='td'>Kategorie ${ categorieName }</div>
				</div>`
			);
		}

		if ( pricePerGrave ) {
			tableDiv.append(
				`<div class='tr price'>
					<div class='th'>Preis pro Ruhestätte:</div>
					<div class='td'>${ pricePerGrave }</div>
				</div>`
			);
		}
	}

	if ( 'fam' === useType ) {
		//const pricePerTree = scene.data.pricePerTree;
		//const priceStr = pricePerTree.toString().replace( /\B(?=(\d{3})+(?!\d))/g, '.' );

		if ( categorieName ) {
			tableDiv.append(
				`<div class='tr cat'>
					<div class='th'>Familienbaum der:</div>
					<div class='td'>Kategorie ${ categorieName }</div>
				</div>`
			);
		}

		if ( pricePerTree ) {
			tableDiv.append(
				`<div class='tr price'>
					<div class='th'>Preis des Familienbaums:</div>
					<div class='td'>${ pricePerTree }</div>
				</div>`
			);
		}

		const gravesAvailable = scene.data.gravesAvailable;
		if ( gravesAvailable ) {
			tableDiv.append(
				`<div class='tr price'>
					<div class='th'>Anzahl der Ruhestätten:</div>
					<div class='td'>${ gravesAvailable }</div>
				</div>`
			);
		}
	}

	table.html( tableDiv );

	const formLink = $( 'a.to-form' );
	const linkSubTextElement = $( 'a.to-form .line-2' );
	const treeId = scene.data.treeId;

	formLink.attr( 'href', `anfrage/?t=${ treeId }` );

	if ( 'gem' === useType ) {
		linkSubTextElement.text( 'und hier Ruhestätte(n) anfragen' );
	}

	if ( 'fam' === useType ) {
		linkSubTextElement.text( 'und diesen Baum anfragen' );
	}
}

export function openInfoBox() {
	$( '#marzipano-overlay #info-box' ).addClass( 'enabled' );
	$( '#marzipano-overlay #info-toggle' ).addClass( 'enabled' );
}

export function closeInfoBox() {
	$( '#marzipano-overlay #info-box' ).removeClass( 'enabled' );
	$( '#marzipano-overlay #info-toggle' ).removeClass( 'enabled' );
}

function addCloseButtonListener() {
	$( '#info-box button.info-close' ).on( 'click', () => {
		closeInfoBox();
		$( '#info-toggle' ).addClass( 'highlight' );

		setTimeout( () => {
			$( '#info-toggle' ).removeClass( 'highlight' );
		}, 1800 );
	} );
}

function addToogleListener() {
	$( '#info-toggle' ).on( 'click', () => {
		$( '#info-toggle' ).toggleClass( 'enabled' );
		$( '#info-box' ).toggleClass( 'enabled' );
		console.log( 'toogle' );
	} );
}

export function addAllInfoBoxListeners() {
	addCloseButtonListener();
	addToogleListener();
}
