window.APP_DATA = {};

function generateScenes( dataArray ) {
	const scenes = [];
	dataArray.forEach( ( element ) => {
		const scene = element;
		scene.levels = standardLevels;
		scene.faceSize = 4096;
		scene.linkHotspots = [];
		scene.infoHotspots = [];

		scenes.push( scene );
	} );
	return scenes;
}

const standardLevels = [
	{
		tileSize: 256,
		size: 256,
		fallbackOnly: true,
	},
	{
		tileSize: 512,
		size: 512,
	},
	{
		tileSize: 512,
		size: 1024,
	},
	{
		tileSize: 512,
		size: 2048,
	},
	{
		tileSize: 512,
		size: 4096,
	},
];

export function genertateAppData( ajaxResponse, postType ) {
	const dataArray = JSON.parse( ajaxResponse );
	const APP_DATA = window.APP_DATA;
	APP_DATA.scenes = generateScenes( dataArray );
	if ( postType === 'tree_panoarama' ) {
		APP_DATA.name = 'Baum-Panoramen';
	}
	if ( postType === 'poi_panoarama' ) {
		APP_DATA.name = 'Rundgang-Panoramen';
	}
	APP_DATA.settings = {
		mouseViewMode: 'drag',
		autorotateEnabled: true,
		fullscreenButton: true,
		viewControlButtons: false,
	};
}
