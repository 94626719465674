const $ = window.jQuery;

/**
 * For panorama links that have a matching attribute, the overlay with the panorama should be opened automatically.
 * If an entry footer is available, the link is copied there. This mechanism is used, among other things, to display
 * a single custom post type.
 */
export function autostart() {
	if ( $( '#primary article .entry-footer' ).length > 0 ) {
		const link = $( 'a.pano[data-autostart="true"]' ).clone();
		$( 'a.pano[data-autostart="true"]' ).remove();
		$( '#primary article .entry-footer' ).append( link );
	}
	$( 'a.pano[data-autostart="true"]' ).trigger( 'click' );
}
