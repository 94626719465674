/* eslint-disable no-console */
const $ = window.jQuery;

export function stopLastSound() {
	const data = window.APP_DATA;
	if ( data.lastSoundAudio ) {
		data.lastSoundAudio.pause();
		data.lastSoundAudio.currentTime = 0;
	}
}

export function updateAudio( scene ) {
	const data = window.APP_DATA;
	const newSoundUrl = scene.data.sound;
	console.log( `new Sound:\n${ newSoundUrl }` );

	/* If the last and the new scene have the same audio,
	just keep playing it in loop*/
	if ( data.lastSoundUrl && newSoundUrl === data.lastSoundUrl ) {
		console.log( 'Keep on rocking' );
		return;
	}

	stopLastSound();

	if ( ! newSoundUrl ) {
		return;
	}

	data.lastSoundUrl = newSoundUrl;
	if ( newSoundUrl ) {
		// eslint-disable-next-line no-undef
		data.lastSoundAudio = new Audio( newSoundUrl );
		data.lastSoundAudio.loop = true;
		if ( $( '#volume-toggle' ).hasClass( 'enabled' ) ) {
			data.lastSoundAudio.play();
		}
	}
}

export function addAudioToggleListener() {
	const data = window.APP_DATA;
	$( '#volume-toggle' ).on( 'click', () => {
		$( '#volume-toggle' ).toggleClass( 'enabled' );
		const enabled = $( '#volume-toggle' ).hasClass( 'enabled' );
		if ( data.lastSoundAudio ) {
			if ( enabled ) {
				data.lastSoundAudio.play();
			} else {
				data.lastSoundAudio.pause();
			}
		}
	} );
}
