const $ = window.jQuery;
let autorotate = null;

export function initAutorotate() {
	// Set up autorotate, if enabled.
	autorotate = window.Marzipano.autorotate( {
		yawSpeed: 0.04,
		targetPitch: 0,
		targetFov: Math.PI / 2,
	} );
}

export function startAutorotate() {
	window.viewer.startMovement( autorotate );
	window.viewer.setIdleMovement( 3000, autorotate );
	$( '#marzipano-overlay #autorotate-toggle' ).addClass( 'enabled' );
}

export function stopAutorotate() {
	window.viewer.stopMovement();
	window.viewer.setIdleMovement( Infinity );
}

export function toggleAutorotate() {
	const toggle = $( '#marzipano-overlay #autorotate-toggle' );
	toggle.toggleClass( 'enabled' );

	if ( toggle.hasClass( 'enabled' ) ) {
		startAutorotate();
	} else {
		stopAutorotate();
	}
}

export function addAutoRotateToggleListener() {
	const toggle = $( '#marzipano-overlay #autorotate-toggle' );
	toggle.on( 'click', () => {
		toggleAutorotate();
	} );
}
