import { openInfoBox } from './info-box';

const $ = window.jQuery;

export function setMarkers() {
	if ( window.APP_DATA.scenes[ 0 ].data.treeId ) {
		window.APP_DATA.scenes.forEach( ( scene ) => {
			const markerId = 'marker-' + scene.data.id;
			const treeId = scene.data.treeId;
			const hotspotContainer = scene.scene.hotspotContainer();
			const position = {
				yaw: scene.data.marker.yaw,
				pitch: scene.data.marker.pitch,
			};
			const marker = $( '#marzipano-tree-marker' ).clone().attr( 'id', markerId ).appendTo( '#marzipano-overlay' );
			marker.find( '.tree-id' ).text( treeId );
			hotspotContainer.createHotspot( document.getElementById( markerId ), position );
			marker.on( 'click', openInfoBox );
		} );
	}
}
