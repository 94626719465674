/* eslint-disable no-console */
import { switchScene } from './sceneSwitch';
const $ = window.jQuery;

function mod( m, n ) {
	return ( ( m % n ) + n ) % n;
}

function previousScene() {
	stepScene( -1 );
}

function nextScene() {
	stepScene( 1 );
}

function stepScene( numberOfSteps ) {
	// If `toPrevious` is `false`, we are going to the next scene, otherwise to the previous.
	const sum = window.APP_DATA.currentSceneIdx + numberOfSteps;
	console.log( window.APP_DATA.scenes );
	const length = window.APP_DATA.scenes.length;
	const idx = mod( sum, length );

	window.APP_DATA.currentSceneIdx = idx;
	switchScene( window.APP_DATA.scenes[ idx ] );
}

// Change scene on button click.
export function addPrevAndNextListeners() {
	$( '#marzipano-overlay' ).on( 'click', 'button.prev', previousScene );
	$( '#marzipano-overlay' ).on( 'click', 'button.next', nextScene );
}
