/* eslint-disable no-console */
// eslint-disable-next-line import/named, import/no-extraneous-dependencies
import screenfull from 'screenfull';
const $ = window.jQuery;

export function initFullscreen() {
	screenfull.on( 'change', function() {
		if ( screenfull.isFullscreen ) {
			$( '#marzipano-overlay' ).addClass( 'fullscreen' );
		} else {
			$( '#marzipano-overlay' ).removeClass( 'fullscreen' );
		}
	} );

	$( '#marzipano-overlay #marzipano-close-button' ).on( 'click', function() {
		if ( screenfull.isFullscreen ) {
			screenfull.toggle();
			$( '#fullscreen-toggle' ).removeClass( 'enabled' );
		}
	} );
}

export function addFullscreenToogleListener() {
	const toggle = $( '#fullscreen-toggle' );
	if ( ! screenfull.isEnabled ) {
		toggle.remove();
		return;
	}

	$( '#fullscreen-toggle' ).on( 'click', function() {
		toggle.toggleClass( 'enabled' );
		screenfull.toggle();
	} );
}

