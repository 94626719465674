import addListeners from './add-listeners';
import { addMobileOrDesktopClass, addTouchOrNoTouchClass } from './label-classes';
import { toggleOverlay } from './toggle-overlay';

export function marzipanoSetup() {
	addMobileOrDesktopClass();
	addTouchOrNoTouchClass();
	toggleOverlay();
	addListeners();
}
