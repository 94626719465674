
// Prevent touch and scroll events from reaching the parent element.
export function stopTouchAndScrollEventPropagation( ) {
	const element = document.getElementById( 'marzipano-overlay' );
	const eventList = [ 'touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel', 'mousewheel' ];
	for ( let i = 0; i < eventList.length; i++ ) {
		element.addEventListener( eventList[ i ], function( event ) {
			event.stopPropagation();
		} );
	}
}
