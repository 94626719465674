/* eslint-disable no-console */
import { stopAutorotate, startAutorotate } from './autorotate';
import { updateSceneList } from './sceneList';
import { updateAudio } from './audio';
import { updateEditLink } from './editButton';
import { updateTitleBar } from './title-bar';
import { updateInfoBox } from './info-box';

export function switchScene( scene ) {
	console.log( 'switchScene called' );
	console.log( 'SCENES' );
	console.log( window.APP_DATA.scenes );
	let index = 0;
	for ( let i = 0; i < window.APP_DATA.scenes.length; i++ ) {
		const loopScene = window.APP_DATA.scenes[ i ];
		if ( scene.data.id === loopScene.data.id ) {
			index = i;
			break;
		}
	}

	window.APP_DATA.currentSceneIdx = index;

	stopAutorotate();
	scene.view.setParameters( scene.data.initialViewParameters );
	scene.scene.switchTo();
	startAutorotate();

	updateInfoBox( scene );
	updateSceneList( scene );
	updateAudio( scene );
	updateEditLink( scene );
	updateTitleBar( scene );
}

export function findSceneById( id ) {
	const scenes = window.APP_DATA.scenes;
	for ( let i = 0; i < scenes.length; i++ ) {
		if ( scenes[ i ].data.id === id ) {
			return scenes[ i ];
		}
	}
	return null;
}

export function findSceneDataById( id ) {
	const data = window.APP_DATA;
	for ( let i = 0; i < data.scenes.length; i++ ) {
		if ( data.scenes[ i ].id === id ) {
			return data.scenes[ i ];
		}
	}
	return null;
}
