'use strict';

/* eslint-disable no-console */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
// eslint-disable-next-line import/no-extraneous-dependencies

// import { loadData } from './loadData';
import * as Marzipano from 'marzipano';
import { getResponsePromise } from './ajax.js';
import { init } from './viewerInit.js';
import { regenerateSceneList } from './sceneList.js';
import { genertateAppData } from './generateAppData.js';
import { startAutorotate } from './autorotate.js';
import { stopLastSound } from './audio.js';
import { switchScene } from './sceneSwitch.js';
import { openInfoBox } from './info-box.js';
import { setMarkers } from './markers.js';
import { updateLabelClasses } from './label-classes.js';

const $ = window.jQuery;
window.Marzipano = Marzipano;

export function toggleOverlay() {
	$( 'body' ).on( 'click', 'a.pano', function() {
		const postType = $( this ).attr( 'data-post-type' );
		const quantity = $( this ).attr( 'data-quantity' );
		console.log( 'quanity: ' + quantity );
		const postId = $( this ).attr( 'data-post-id' );
		// console.log( postType );
		showPanoramaOverlay( );
		window.APP_DATA.currentSceneIdx = 0;

		getResponsePromise( postType, quantity, postId ).then( ( response ) => {
			genertateAppData( response, postType );
			console.log( 'window.APP_DATA:' );
			console.log( window.APP_DATA );
			init();
			regenerateSceneList();
			switchScene( window.APP_DATA.scenes[ 0 ] );
			startAutorotate();
			if ( 800 <= window.innerWidth ) {
				openInfoBox();
			}
			setMarkers();
			updateLabelClasses();
		} ).catch( ( error ) => {
			console.log( error );
		} );
	} );

	$( '#marzipano-overlay' ).on( 'click', '.close-button', function() {
		closeMarzipanoOverlay();
	} );

	function showPanoramaOverlay( ) {
		$( '#marzipano-overlay' ).show();

		$( 'html' ).css( 'overflow-y', 'hidden' );
		$( 'body' ).removeClass( 'wrp-overlay-inactive' ).addClass( 'wrp-overlay-active' );
	}

	function closeMarzipanoOverlay() {
		$( 'html' ).css( 'overflow-y', 'auto' );
		$( '#marzipano-overlay' ).removeClass( 'tree' ).removeClass( 'poi' );

		$( '#marzipano-overlay' ).hide();
		$( 'body' ).removeClass( 'wrp-overlay-active' ).addClass( 'wrp-overlay-inactive' );

		stopLastSound();
	}
}

