/* eslint-disable no-console */

const panoAjaxObject = window.panoAjaxObject;
const $ = window.jQuery;

export function getResponsePromise( postType, quantity, postId = '' ) {
	const url = panoAjaxObject.ajaxURL;
	const reqData = {};
	reqData.nonce = panoAjaxObject.nonce;
	reqData.action = 'get-pano-data';
	reqData.postType = postType;
	reqData.quantity = quantity;
	reqData.postId = postId;

	console.log( 'reqData' );
	console.log( reqData );

	return new Promise( ( resolve, reject ) => {
		$.ajax( {
			url,
			type: 'post',
			data: reqData,
			async: false,
			success( response ) {
				resolve( response );
			},
			error( error ) {
				reject( error );
			},
		} );
	} );
}
