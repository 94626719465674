/* eslint-disable no-console */
import { findSceneById, switchScene } from './sceneSwitch';

const $ = window.jQuery;

export function updateSceneList( scene ) {
	console.log( 'updateSceneList' );
	const sceneElements = $( '#scene-list ul.scenes .scene' );
	console.log( sceneElements.length );
	for ( let i = 0; i < sceneElements.length; i++ ) {
		const el = $( sceneElements[ i ] );
		console.log( el.attr( 'data-scene-id' ) );
		if ( el.attr( 'data-scene-id' ) === scene.data.id ) {
			el.addClass( 'current' );
		} else {
			el.removeClass( 'current' );
		}
	}
}

export function regenerateSceneList() {
	const sceneUl = $( '#scene-list ul.scenes' );
	sceneUl.empty();
	window.APP_DATA.scenes.forEach( function( scene ) {
		const id = scene.data.id;
		const name = scene.data.name;
		sceneUl.append( `<a href='javascript:void(0)' data-scene-id='${ id }' class="scene"><li class="text">${ name }</li></a>` );
	} );
}

const sceneListToggleButton = $( '#scene-list-toggle' );
const sceneListDiv = $( 'div#scene-list' );

function addSceneListToggleListener() {
	sceneListToggleButton.on( 'click', toggleSceneList );
}

export function showSceneList() {
	sceneListDiv.addClass( 'enabled' );
	sceneListToggleButton.addClass( 'enabled' );
}

export function hideSceneList() {
	sceneListDiv.removeClass( 'enabled' );
	sceneListToggleButton.removeClass( 'enabled' );
}

function toggleSceneList() {
	sceneListDiv.toggleClass( 'enabled' );
	sceneListToggleButton.toggleClass( 'enabled' );
}

function addListItemListener() {
	$( '#scene-list ul.scenes' ).on( 'click', 'a', function() {
		const id = $( this ).attr( 'data-scene-id' );
		const scene = findSceneById( id );
		switchScene( scene );
	} );
}

export function addAllSceneListListeners() {
	addSceneListToggleListener();
	addListItemListener();
}
