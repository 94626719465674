/* eslint-disable no-console */
import * as Marzipano from 'marzipano';
import { switchScene } from './sceneSwitch';

window.viewer = null;

export function init() {
	// Grab elements from DOM.
	const panoElement = document.querySelector( '#pano' );

	const viewerOpts = {
		controls: {
			mouseViewMode: window.APP_DATA.settings.mouseViewMode,
		},
	};

	// Initialize viewer.
	const viewer = new window.Marzipano.Viewer( panoElement, viewerOpts );
	window.viewer = viewer;

	// Create scenes.
	const scenes = window.APP_DATA.scenes.map( function( d ) {
		console.log( 'd:' );
		console.log( d );
		const source = Marzipano.ImageUrlSource.fromString(
			d.tileURL + '/{z}/{f}/{y}/{x}.jpg',
			{
				cubeMapPreviewUrl: d.tileURL + '/preview.jpg',
			}
		);
		const geometry = new Marzipano.CubeGeometry( d.levels );

		const limiter = Marzipano.RectilinearView.limit.traditional( d.faceSize, 100 * Math.PI / 180, 120 * Math.PI / 180 );
		const view = new Marzipano.RectilinearView( d.initialViewParameters, limiter );

		const scene = viewer.createScene( {
			source,
			geometry,
			view,
			pinFirstLevel: true,
		} );

		return {
			data: d,
			scene,
			view,
		};
	} );

	window.APP_DATA.scenes = scenes;

	// Display the initial scene.
	switchScene( scenes[ 0 ] );
}

/*
export function switchScene( scene ) {
	scene.view.setParameters( scene.data.initialViewParameters );
	scene.scene.switchTo();
	updateSceneList( scene );
}
*/
