const $ = window.jQuery;

/**
 * If the viewport is less than 500px height or weight, the function will add
 * a class 'mobile' to the element with id marzipano-overlay., or a class 'desktop' otherwise.
 */
export function addMobileOrDesktopClass() {
	const mo = $( '#marzipano-overlay' );

	// Detect desktop or mobile mode.
	if ( window.matchMedia ) {
		const setMode = function() {
			if ( mql.matches ) {
				mo.addClass( 'mobile' ).removeClass( 'desktop' );
			} else {
				mo.addClass( 'desktop' ).removeClass( 'mobile' );
			}
		};

		// eslint-disable-next-line no-undef
		const mql = matchMedia( '(max-width: 500px), (max-height: 500px)' );
		setMode();

		mql.addEventListener( 'change', () => {
			setMode();
		} );
	} else {
		mo.addClass( 'desktop' );
	}
}

/**
 * Detect whether we are on a touch device or not.
 * Will add a class correspondig class 'touch' or 'no-touch' to the body element.
 */
export function addTouchOrNoTouchClass() {
	$( 'body' ).addClass( 'no-touch' );

	window.addEventListener( 'touchstart', function() {
		$( 'body' ).addClass( 'touch' ).removeClass( 'no-touch' );
	} );
}

/**
 * Call after APP_DATA object is created
 */
export function updateLabelClasses() {
	const scenes = window.APP_DATA.scenes;
	// eslint-disable-next-line no-console
	console.log( scenes );
	if ( 1 === scenes.length ) {
		$( '#marzipano-overlay' ).addClass( 'single' );
	} else {
		$( '#marzipano-overlay' ).removeClass( 'single' );
	}
	const postType = scenes[ 0 ].data.postType;
	if ( 'tree_panorama' === postType ) {
		$( '#marzipano-overlay' ).addClass( 'tree' ).removeClass( 'poi' );
	}
	if ( 'poi_panorama' === postType ) {
		$( '#marzipano-overlay' ).addClass( 'poi' ).removeClass( 'tree' );
	}
}

export function sok() {
	if ( window.panoAjaxObject && window.panoAjaxObject.sok ) {
		$( 'body' ).addClass( window.panoAjaxObject.sok );
	}
}
