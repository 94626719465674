import { addAudioToggleListener } from './audio';
import { addAutoRotateToggleListener, initAutorotate } from './autorotate';
import { addFullscreenToogleListener, initFullscreen } from './fullscreen';
import { addAllInfoBoxListeners } from './info-box';
import { autostart } from './autostart';
import { addPrevAndNextListeners } from './prev-and-next';
import { addAllSceneListListeners } from './sceneList';
import { addDeviceOrientationListener } from './device-orientation';
import { stopTouchAndScrollEventPropagation } from './event-blocking';

export default function addListeners() {
	window.APP_DATA.currentSceneIdx = 0;
	stopTouchAndScrollEventPropagation();
	autostart(); // used in single cpt view
	addAllSceneListListeners();
	initAutorotate();
	addAutoRotateToggleListener();
	addAudioToggleListener();
	addPrevAndNextListeners();
	addAllInfoBoxListeners();
	initFullscreen();
	addFullscreenToogleListener();
	addDeviceOrientationListener();
}

